.quadloop__solutions h2{
    font-family: var(--font-family);
    color: var(--color-2);
    font-size: 36px;
    font-weight: bold;
    padding-bottom: 4rem;
   }

@media screen and (min-width:1024px) {
    .quadloop__solutions h2{
        padding-top: 6rem;
       }
}

.quadloop__solutions_images{
    display: flex;
    justify-content: space-around;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

@media screen and (min-width:768px) {
    .quadloop__solutions_images{
        padding: 0rem 12rem;
       }
}

.quadloop__solutions_images a:hover{
    scale: 1.03;
    transition: 0.4s;
    cursor: pointer;
}

.quadloop__solutions_others{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.quadloop__solutions_others img{
    width: 180px;
    padding: 1rem;
}

@media screen and (min-width:560px) {
    .quadloop__solutions_others img{
        width: 250px;
       }
}

@media screen and (min-width:1024px) {
    .quadloop__solutions_others img{
        width: 350px;
       }
}

.quadloop__solutions_lamp{
    width: auto;
}





