

.quadloop__challenges h2{
    font-size: 36px;
    font-family: var(--font-family);
    color: var(--color-5);
    font-weight: bolder;
    padding-bottom: 6rem;
    padding-top: 10rem;
}

@media screen and (max-width:768px) {
    .quadloop__challenges h2{
        padding-bottom: 4rem;
        padding-top: 8rem;
    }
    
}

.quadloop__challenges_image{
    display: flex;
    justify-content: center;
}

.quadloop__challenges_image img{
    width: 800px;
}

@media screen and (max-width:768px) {
    .quadloop__challenges_image img{
        width: 100%;
        padding-bottom: 6rem;
    }
}

.quadloop__challenges_04{
    display: flex;
    justify-content: center;
    padding: 1rem;    
}

@media screen and (min-width:1130px) {
    .quadloop__challenges_04{

        padding: 4rem;    
    }
    
}