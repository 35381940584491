.quadloop__NoPage{
    background-image: url(../Assets/cellfarm.png);
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.quadloop__NoPage_content{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;
}

.quadloop__NoPage_content h2{
    color: var(--color-2);
    font-family: var(--font-family);
    font-size: 150px;
}

.quadloop__NoPage_content h3{
    color: var(--color-3);
    font-family: var(--font-family);
    font-size: 36px;
    text-align: center;
}

.quadloop__NoPage_content p{
    color: var(--color-3);
    font-family: var(--font-family);
    font-size: 14px;
    text-align: center;
    padding: 1rem;
}

.quadloop__NoPage_content a{
    color: var(--color-bg);
    font-family: var(--font-family);
    background-color: var(--color-4);
    padding: 0.5rem 1rem;
    margin: 1rem;
    border-radius: 1rem;
}

.quadloop__NoPage_content a:hover{
    transition: 0.5s ease-out;
    letter-spacing: 0.5px;
    color: var(--color-bg);

}