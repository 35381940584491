*{
  box-sizing: border-box;
  scroll-behavior: smooth;
  margin: 0;
  padding: 0;
}

p {
    font-family: var(--font-family);
  }

body{
    background-color: var(--color-bg);
}

a {
  color: unset;
  text-decoration: none;
}

.quadloop__head{
  background-image: url(Assets/Home-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 30%;
}

.content__padding{
  padding: 0rem 6rem;
}

@media screen and (max-width:680px) {
  .content__padding{
    padding: 0rem 2rem;
  }
  
}

@media screen and (min-width:1440px) {
  .content__padding{
    padding: 0rem 16rem;
  }
  
}

.quadloop__about_section{
  background-image: url(Assets/about-bg.png);
  background-repeat: no-repeat;
  background-position: 0% 80%;
}

.quadloop__challenges_section{
  background-image: url(Assets/challenges-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 0%;
}

.quadloop__solutions_section{
  background-image: url(Assets/solutions-bg.png);
  background-repeat: no-repeat;
  background-position: 100% 250%;
}

.quadloop__impact_section{
  background-image: url(Assets/Impact-bg.svg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 0%;
}

.quadloop__partners_section{

  padding-top: 6rem;
  padding-bottom: 6rem;
}
.quadloop__contact_section{
  background-image: url(Assets/contact.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% 0%;
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.quadloop__footer_section{

  padding-top: 6rem;
  padding-bottom: 6rem;
}

/* reveal section*/
.reveal{
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active{
  transform: translateY(0);
  opacity: 1;
}


/**
 * ----------------------------------------
 * animation slide-in-blurred-left
 * ----------------------------------------
 */
.slide-in-blurred-left {
	-webkit-animation: slide-in-blurred-left 1s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
	        animation: slide-in-blurred-left 1s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}


 @-webkit-keyframes slide-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
            transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
            transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}



/**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */

.slide-in-right {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s both;
	        animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s both;
}



 @-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
}



/**
 * ----------------------------------------
 * animation scale-up-tr
 * ----------------------------------------
 */
.scale-up-tr {
	-webkit-animation: scale-up-tr 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-tr 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-9-1 11:29:21
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */


 @-webkit-keyframes scale-up-tr {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}
@keyframes scale-up-tr {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 100% 0%;
            transform-origin: 100% 0%;
  }
}

